<template>
  <el-main>
    <el-form class="el-form-search" label-width="100px">
      <el-form-item label="物业名称：">
        <el-input size="small" v-model="searchForm.property_name" placeholder="请输入物业名称"></el-input>
      </el-form-item>
      <el-form-item label="负责人姓名：">
        <el-input size="small" v-model="searchForm.duty_name" placeholder="请输入负责人姓名"></el-input>
      </el-form-item>
      <el-form-item label="联系电话：">
        <el-input size="small" type="number" v-model="searchForm.mobile" placeholder="请输入联系电话"></el-input>
      </el-form-item>
      <el-form-item label="添加时间：">
        <el-date-picker size="small" v-model="searchForm.start_time" type="daterange" range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
      </el-form-item>
      <el-form-item label=" " label-width="25px">
        <el-button size="small" type="primary" @click="getList()">搜索</el-button>
        <el-button size="small" type="text" @click="clearSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="property_name" label="物业名称" align="center"></el-table-column>
      <el-table-column prop="duty_name" label="负责人姓名" align="center"></el-table-column>
      <el-table-column prop="mobile" label="联系电话" align="center"></el-table-column>
      <el-table-column prop="create_time" label="添加时间" width="200" align="center">
        <template v-slot="{ row }">{{ getDateformat(row.create_time) }}</template>
      </el-table-column>
    </el-table>
    <Paging :total="total_number" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData"></Paging>
  </el-main>
</template>
<script>
import _ from 'lodash';
import Paging from '@/components/paging';
import { getDateformat } from '@/util/getDate';

export default {
  components: {
    Paging,
  },
  data() {
    return {
      list: [],
      total_number: 0,
      searchForm: {
        page: 1,
        rows: 10,
        property_name: '',
        duty_name: '',
        mobile: '',
        start_time: '',
        end_time: '',
      },
      id: '',
    };
  },
  created() {
    this.getList();
  },
  methods: {
    // 搜索
    search() {
      this.page = 1;
      this.getList();
    },
    // 清空搜索
    clearSearch() {
      this.searchForm = {
        page: 1,
        rows: 10,
        property_name: '',
        duty_name: '',
        mobile: '',
        start_time: '',
        end_time: '',
      };
      this.getList();
    },
    // 翻页
    updateData(val, status) {
      if (status == 0) {
        this.searchForm.rows = val;
        this.getList();
      } else {
        this.searchForm.page = val;
        this.getList();
      }
    },
    // 获取物业列表
    getList() {
      let searchForm = _.cloneDeep(this.searchForm);
      if (searchForm.start_time) {
        searchForm.end_time = searchForm.start_time[1] / 1000;
        searchForm.start_time = searchForm.start_time[0] / 1000;
      }
      this.$axios.post(this.$api.user.propertyList, searchForm).then(res => {
        if (res.code == 0) {
          this.list = res.result.list;
          this.total_number = res.result.total_number;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 时间格式
    getDateformat(val) {
      return getDateformat(val);
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
  .el-table {
    border-left: 1px solid #e8eef1;
    border-right: 1px solid #e8eef1;
    /deep/ .el-input__inner {
      border-color: #fff;
    }
    /deep/ .el-input__inner:focus {
      border-color: #409eff;
    }
  }
}
</style>
